import { Button, Stack, Typography } from "@mui/joy";
import Payment from "./payment";
import { useSelector } from "react-redux";
import { OpenInNew } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function Payments({ payments }) {
  const { restaurant } = useSelector((state) => state.restaurant);
  const { subscription } = restaurant;

  const header = {
    date: "Date",
    description: "Description",
    amount: "Amount",
    status: "Status",
  };

  const NoPayments = () => (
    <Stack minHeight="20vh" justifyContent="center" alignItems="center">
      <Typography level="body-sm" textAlign="center">
        No Payment
      </Typography>
    </Stack>
  );

  const FailedPayment = () =>
    subscription.razor_status === "pending" ? (
      <Payment
        payment={{
          created_on: null,
          description: "Recent Payment Failed.",
          retryLink: subscription.razor_short_url,
          razor_amount: null,
          is_successful: false,
        }}
      />
    ) : null;

  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography level="title-lg">Payments</Typography>
        {restaurant.subscription.razor_short_url && (
          <Link to={restaurant.subscription.razor_short_url} target="_blank">
            <Button
              size="sm"
              variant="outlined"
              color="neutral"
              endDecorator={<OpenInNew />}
            >
              Payment Link
            </Button>
          </Link>
        )}
      </Stack>
      <Stack spacing={1.5}>
        <Payment isHeader payment={header} />
        <FailedPayment />
        {payments.map((payment, index) => (
          <Payment key={index} payment={payment} />
        ))}
        {!payments.length && <NoPayments />}
      </Stack>
    </Stack>
  );
}
