import { useSelector } from "react-redux";
import { Grid, Stack, Typography } from "@mui/joy";

import Plan from "./plan";
import {
  BASIC_PLAN_BENEFITS,
  PRO_PLAN_BENEFITS,
  PLAN,
} from "../../../../../utils/config";

export default function Plans({ plans }) {
  const { restaurant } = useSelector((state) => state.restaurant);

  const getBasicPlan = () => {
    const plan = plans.filter((i) => i.code === PLAN.BASIC)[0];
    const isActive = restaurant.subscription.plan === plan.id;
    const paymentNotAuthorized =
      (restaurant.subscription.razor_status === "created" ||
        restaurant.subscription.razor_status === "APPROVAL_PENDING") &&
      isActive;

    return {
      id: plan.id,
      code: PLAN.BASIC,
      name: "Basic",
      isMostPopular: false,
      benefits: BASIC_PLAN_BENEFITS,
      pricing: plan.razor_item_amount / 100,
      isActive,
      paymentNotAuthorized,
    };
  };
  const getProPlan = () => {
    const plan = plans.filter((i) => i.code === PLAN.PRO)[0];
    const isActive = restaurant.subscription.plan === plan.id;
    const paymentNotAuthorized =
      (restaurant.subscription.razor_status === "created" ||
        restaurant.subscription.razor_status === "APPROVAL_PENDING") &&
      isActive;

    return {
      id: plan.id,
      code: PLAN.PRO,
      name: "Pro",
      isMostPopular: true,
      benefits: PRO_PLAN_BENEFITS,
      pricing: plan.razor_item_amount / 100,
      isActive,
      paymentNotAuthorized,
    };
  };

  const enhancedPlans = [getBasicPlan(), getProPlan()];

  return (
    <Stack spacing={1}>
      <Typography level="title-lg">Pricing and Plans</Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {enhancedPlans.map((plan, index) => (
          <Grid xs={12} md={6} lg={5} xl={4} key={index}>
            <Plan plan={plan} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
