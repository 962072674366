import { Stack, Typography } from "@mui/joy";

export default function Body() {

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "200px",
      }}
    >
      <Stack spacing={1}>
        <Typography level="title-md" textAlign="center">
          <b>No Enough Data</b> to show Summary!
        </Typography>

        <Typography level="body-xs" textAlign="center">
          Please refresh after few days.
        </Typography>
      </Stack>
    </Stack>
  );
}
