import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setNotify } from "../../../../../redux/utils";

import {
  Button,
  Card,
  CardActions,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemDecorator,
  Typography,
  Stack,
} from "@mui/joy";

import { Check, Close, KeyboardArrowRight } from "@mui/icons-material";

import { getAuthorizationHeader } from "../../../../../utils/helpers";
import { DOMAIN, getCurrency, PLAN } from "../../../../../utils/config";
import EndSubscription from "./endSubscription";

import moment from "moment";
import axios from "axios";

export default function Plan({ plan }) {
  const { restaurant } = useSelector((state) => state.restaurant);

  const [subscribing, setSubscribing] = useState(false);

  const dispatch = useDispatch();

  const handleSubscribe = () => {
    setSubscribing(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/billings/subscription/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurant.uid,
      },
      data: {
        plan_id: plan.id,
      },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        setSubscribing(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <Card
      size="lg"
      variant={plan.isActive ? "solid" : "outlined"}
      color="neutral"
      invertedColors={plan.isActive}
      sx={{
        bgcolor: plan.isActive ? "neutral.500" : "#fff",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Typography level="h2">{plan.name}</Typography>
          {plan.isMostPopular && (
            <Chip size="sm" variant="outlined">
              Most Popular
            </Chip>
          )}
        </Stack>
        {plan.isActive && Boolean(restaurant.trial_ending_on) && (
          <Typography level="body-xs">
            Trying out until{" "}
            <b>{moment(restaurant.trial_ending_on).format("DD MMM, YYYY")}</b>
          </Typography>
        )}
      </Stack>

      <Divider inset="none" />
      <List
        size="sm"
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1.1fr",
          mx: "calc(-1 * var(--ListItem-paddingX))",
        }}
      >
        {plan.benefits.map((benefit, index) => (
          <ListItem
            key={index}
            color={benefit.isIncluded ? "neutral" : "danger"}
          >
            <ListItemDecorator>
              {benefit.isIncluded ? (
                <Check color="success" />
              ) : (
                <Close color="danger" />
              )}
            </ListItemDecorator>
            {benefit.name}
          </ListItem>
        ))}
      </List>
      <Divider inset="none" />
      <CardActions>
        <Typography level="title-lg" sx={{ mr: "auto" }}>
          {getCurrency(restaurant.currency)}
          {plan.pricing}{" "}
          <Typography fontSize="sm" textColor="text.tertiary">
            / month
          </Typography>
        </Typography>
        <Stack direction="row" spacing={2}>
          <Stack direction="row" spacing={0.5}>
            {plan.isActive ? (
              restaurant.subscription.is_cancelled ? (
                <Button disabled color="neutral" size="sm" variant="outlined">
                  Cancelled
                </Button>
              ) : (
                <EndSubscription />
              )
            ) : null}
          </Stack>
          <Button
            color="neutral"
            size="sm"
            disabled={
              subscribing || (plan.isActive && !plan.paymentNotAuthorized)
            }
            variant={plan.isActive ? "solid" : "outlined"}
            endDecorator={plan.isActive ? null : <KeyboardArrowRight />}
            onClick={
              plan.isActive
                ? plan.paymentNotAuthorized
                  ? () => {
                      window.open(
                        restaurant.subscription.razor_short_url,
                        "_blank"
                      );
                    }
                  : null
                : handleSubscribe
            }
          >
            {plan.isActive
              ? plan.paymentNotAuthorized
                ? "Complete Payment"
                : "Active"
              : plan.code === PLAN.BASIC || plan.code === PLAN.PRO
              ? "Subscribe"
              : "Contact Us"}
          </Button>
        </Stack>
      </CardActions>
    </Card>
  );
}
