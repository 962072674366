import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";

import {
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Stack,
  Typography,
  Tooltip,
  Drawer,
} from "@mui/joy";

import {
  CardGiftcard,
  CheckCircleOutline,
  FeedbackOutlined,
  AutoStoriesOutlined,
  RoomServiceOutlined,
  OpenInNew,
  PeopleOutline,
  ReceiptLong,
  Settings,
  TableBarOutlined,
  AutoAwesome,
  CreditCard,
  Circle,
} from "@mui/icons-material";

import {
  PLAN_LEVEL,
  PLAN,
  isMobile,
  CUSTOMER_PORTAL_BASE_URL,
} from "../../../utils/config";
import urls from "../../../utils/urls.json";
import { setSidebarOpen } from "../../../redux/utils";

export default function Sidebar({ width }) {
  const {
    restaurant,
    bookingCodeRequests,
    callingTables,
    requestingBill,
    activeOrders,
  } = useSelector((state) => state.restaurant);

  const { activePathId, sidebarOpen } = useSelector((state) => state.utils);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const menuListAboveItems = [
    {
      title: urls.restaurant.name,
      code: urls.restaurant.pathId,
      link: `${restaurant.uid}`,
      icon: <CheckCircleOutline />,
      requiredPlan: PLAN.PRO,
      requiredPlanLevel: PLAN_LEVEL[PLAN.PRO],
      hide: isMobile(),
    },
    {
      title: urls.restaurant.children.orders.name,
      code: urls.restaurant.children.orders.pathId,
      link: `${restaurant.uid}/${urls.restaurant.children.orders.path}`,
      icon: <RoomServiceOutlined />,
      requiredPlan: PLAN.PRO,
      requiredPlanLevel: PLAN_LEVEL[PLAN.PRO],
      hide: false,
    },
    {
      title: urls.restaurant.children.tables.name,
      code: urls.restaurant.children.tables.pathId,
      link: `${restaurant.uid}/${urls.restaurant.children.tables.path}`,
      icon: <TableBarOutlined />,
      requiredPlan: PLAN.PRO,
      requiredPlanLevel: PLAN_LEVEL[PLAN.PRO],
      hide: false,
    },
    {
      title: urls.restaurant.children.menu.name,
      code: urls.restaurant.children.menu.pathId,
      link: `${restaurant.uid}/${urls.restaurant.children.menu.path}`,
      icon: <AutoStoriesOutlined />,
      requiredPlan: PLAN.BASIC,
      requiredPlanLevel: PLAN_LEVEL[PLAN.BASIC],
      hide: isMobile(),
    },
    {
      title: "See Menu",
      link: `${CUSTOMER_PORTAL_BASE_URL}/${restaurant.uid}`,
      openInNewTab: true,
      icon: <AutoStoriesOutlined />,
      hide: !isMobile(),
    },
    {
      title: urls.restaurant.children.customers.name,
      code: urls.restaurant.children.customers.pathId,
      link: `${restaurant.uid}/${urls.restaurant.children.customers.path}`,
      icon: <PeopleOutline />,
      requiredPlan: PLAN.PRO,
      requiredPlanLevel: PLAN_LEVEL[PLAN.PRO],
      hide: isMobile(),
    },
    {
      title: urls.restaurant.children.coupons.name,
      code: urls.restaurant.children.coupons.pathId,
      link: `${restaurant.uid}/${urls.restaurant.children.coupons.path}`,
      icon: <CardGiftcard />,
      requiredPlan: PLAN.PRO,
      requiredPlanLevel: PLAN_LEVEL[PLAN.PRO],
      hide: isMobile(),
    },
  ];

  const menuListBelowItems = [
    {
      title: "Feedback",
      link: `https://forms.gle/athPkvhvMqYnYk86A`,
      openInNewTab: true,
      icon: <FeedbackOutlined />,
      hide: isMobile(),
    },
    {
      title: urls.restaurant.children.billing.name,
      code: urls.restaurant.children.billing.pathId,
      link: `${restaurant.uid}/${urls.restaurant.children.billing.path}`,
      icon: <CreditCard />,
      hide: isMobile(),
    },
    {
      title: urls.restaurant.children.settings.name,
      code: urls.restaurant.children.settings.pathId,
      link: `${restaurant.uid}/${urls.restaurant.children.settings.path}`,
      icon: <Settings />,
      hide: isMobile(),
    },
  ];

  const PlanIcon = ({ plan, disabled }) => {
    switch (plan) {
      case PLAN.PRO:
        return (
          <sup
            style={{
              marginLeft: "5px",
              display: disabled ? "none" : "block",
            }}
          >
            <AutoAwesome
              color="warning"
              sx={{
                fontSize: "14px",
              }}
            />
          </sup>
        );
      default:
        return null;
    }
  };

  const Logo = () => (
    <Stack direction="row" spacing={0.5}>
      <Typography level="h3" component="h1" fontWeight={500}>
        Smart
      </Typography>
      <Typography level="h3" component="h1" fontWeight={700}>
        Dine-in
      </Typography>
    </Stack>
  );

  const TooltipWrapper = ({ title, show, children }) =>
    show ? (
      <Tooltip title={title} variant="solid" size="sm" placement="right">
        {children}
      </Tooltip>
    ) : (
      children
    );

  const MenuItem = ({ item }) => {
    const isDisabled = item.requiredPlanLevel
      ? restaurant.plan_code
        ? PLAN_LEVEL[restaurant.plan_code] < item.requiredPlanLevel
        : true
      : false;

    const disabledMessage =
      isDisabled && `Subscribe to ${item.requiredPlan} Plan`;

    return (
      <TooltipWrapper title={disabledMessage} show={isDisabled}>
        <ListItem>
          <ListItemButton
            disabled={isDisabled}
            selected={activePathId === item.code}
            color={activePathId === item.code ? "primary" : "neutral"}
            onClick={() => {
              if (item.openInNewTab) {
                window.open(item.link, "_blank");
              } else {
                navigate(item.link);
              }
              dispatch(setSidebarOpen(false));
            }}
          >
            <ListItemDecorator>{item.icon}</ListItemDecorator>
            <ListItemContent
              sx={{
                fontWeight: "500",
              }}
            >
              <Stack direction="row">
                {item.title}
                <PlanIcon plan={item.requiredPlan} disabled={!isDisabled} />
                {item.code === urls.restaurant.children.orders.pathId &&
                activeOrders.length ? (
                  <sup
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        backgroundColor: "orange",
                        borderRadius: "50%",
                        height: "14px",
                        width: "14px",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: "47%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "#fff",
                          fontSize: "10px",
                        }}
                      >
                        {activeOrders.length}
                      </span>
                    </div>
                  </sup>
                ) : null}
                {item.code === urls.restaurant.children.tables.pathId &&
                bookingCodeRequests.length ? (
                  <sup
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <Circle
                        sx={{
                          fontSize: "12px",
                          color: "#F7B200",
                        }}
                      />
                    </Stack>
                  </sup>
                ) : null}
                {item.code === urls.restaurant.children.tables.pathId &&
                callingTables.length ? (
                  <sup
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <RoomServiceOutlined
                        sx={{
                          fontSize: "14px",
                          color: "#fd1c1c",
                        }}
                      />
                    </Stack>
                  </sup>
                ) : null}
                {item.code === urls.restaurant.children.tables.pathId &&
                requestingBill.length ? (
                  <sup
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={0.5}>
                      <ReceiptLong
                        sx={{
                          fontSize: "14px",
                          color: "#F7B200",
                        }}
                      />
                    </Stack>
                  </sup>
                ) : null}
                {item.openInNewTab && (
                  <sup>
                    <OpenInNew
                      sx={{
                        height: "10px",
                      }}
                    />
                  </sup>
                )}
              </Stack>
            </ListItemContent>
          </ListItemButton>
        </ListItem>
      </TooltipWrapper>
    );
  };

  const MenuList = ({ menuList }) => (
    <List>
      <Stack spacing={1}>
        {menuList
          .filter((i) => !i.hide)
          .map((item, index) => (
            <MenuItem key={index} item={item} />
          ))}
      </Stack>
    </List>
  );

  const SidebarWrapper = ({ children }) => (
    <>
      <Stack
        sx={{
          width: `${width}px`,
          bgcolor: "#fff",
          height: "100vh",
          position: "sticky",
          top: 0,
          display: {
            xs: "none",
            lg: "flex",
          },
        }}
        boxShadow="sm"
        justifyContent="space-between"
        spacing={2}
      >
        {children}
      </Stack>
      <Drawer
        open={sidebarOpen}
        onClose={() => dispatch(setSidebarOpen(false))}
        sx={{
          display: {
            xs: "flex",
            lg: "none",
          },
        }}
      >
        <Stack
          sx={{
            bgcolor: "#fff",
            height: "100vh",
            position: "sticky",
            top: 0,
          }}
          boxShadow="sm"
          justifyContent="space-between"
          spacing={2}
        >
          {children}
        </Stack>
      </Drawer>
    </>
  );

  return (
    <SidebarWrapper>
      <Stack px={2.5} pt={2}>
        <Stack spacing={1}>
          <Logo />
          <MenuList menuList={menuListAboveItems} />
        </Stack>
      </Stack>
      <Stack px={2.5} pb={2}>
        <MenuList menuList={menuListBelowItems} />
      </Stack>
    </SidebarWrapper>
  );
}
