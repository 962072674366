import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { setActivePathId } from "../../../../redux/utils";

import urls from "../../../../utils/urls.json";
import BodyWrapper from "../components/bodyWrapper";
import Body from "./body";

export default function Coupons() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivePathId(urls.restaurant.children.coupons.pathId));
  }, [dispatch]);
  return (
     <BodyWrapper withStack>
      <Body />
    </BodyWrapper>
  );
}
