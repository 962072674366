import { Stack, Typography } from "@mui/joy";

export default function Body() {

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "305px",
      }}
    >
      <Stack spacing={1}>
        <Typography level="title-md" textAlign="center">
          <b>Coupons</b> will be available soon!
        </Typography>
        <Typography level="body-xs" textAlign="center">
          Please come back after few days.
        </Typography>
      </Stack>
    </Stack>
  );
}
